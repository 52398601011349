import { Language } from "../LangSelector/types";
import { DiscordIcon, GithubIcon, TelegramIcon, TwitterIcon } from "../Svg";
import { FooterLinkType } from "./types";

export const footerLinks: FooterLinkType[] = [];

export const socials = [
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://twitter.com/9mm_pro",
  },
  {
    label: "Telegram",
    icon: TelegramIcon,
    href: "https://t.me/ninemmpro",
  },
  {
    label: "Github",
    icon: GithubIcon,
    href: "https://github.com/9mmPro/",
  },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
