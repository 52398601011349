import { pulseTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { FarmConfigV3 } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedFarms: FarmConfigV3[] = [
  {
    pid: 1,
    lpAddress: '0xa2b8f577ab43bc7d01ba96b743e8641748d6db3c',
    token0: pulseTokens.weth,
    token1: pulseTokens.ninemm,
    feeAmount: FeeAmount.HIGH,
  }
]

export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedFarms,

])
