import { sonicTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { FarmConfigV3 } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

const v3TopFixedFarms: FarmConfigV3[] = [
  {
    pid: 1,
    lpAddress: '0x51ef3d3365b2c14f4c1a8ad308d5aac0fe67bc1d',
    token0: sonicTokens.usdc,
    token1: sonicTokens.weth,
    feeAmount: FeeAmount.MEDIUM,
  },

]

export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedFarms,

])
