import { useQuery } from '@tanstack/react-query'
import { ProposalState, Proposal } from 'state/types'

export const getCoreProposal = async (type: ProposalState): Promise<Proposal[]> => {

  return []
}

export const useVotingStatus = () => {
  const { data: votingStatus = null } = useQuery({
    queryKey: ['anyActiveSoonCoreProposals'],

    queryFn: async () => {
      const activeProposals = await getCoreProposal(ProposalState.ACTIVE)
      if (activeProposals.length) {
        return 'vote_now'
      }
      const soonProposals = await getCoreProposal(ProposalState.PENDING)
      if (soonProposals.length) {
        return 'soon'
      }
      return null
    },

    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
  return votingStatus
}
