import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | 9mm Pro',
  defaultTitle: '9mm Pro',
  description: 'Discover 9mm Dex, a multi-chain DEX and OTC marketplace with revenue sharing.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@9mm_pro',
    site: '@9mm_pro',
  },
  openGraph: {
    title: "9mm Pro - Multi-Chain Dex",
    description: '9mm Pro, multi-chain, DEX, OTC marketplace, NFT collection, Pulse OG, Based OG, crypto, blockchain with revenue sharing.',
    images: [{ url: 'https://dex.9mm.pro/og.png' }],
  },
}
